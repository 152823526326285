import axios from "@/requests/axios";
import { setStorage, getStorage } from "@/helpers/storage";
import _ from 'lodash';
import { execute_json } from "@/helpers/requests";

const state = () => ({
  index: [],
  loaded: false

});

// getters
const getters = {
  byId: (state) => (id) => {
    if (!state.loaded) {
      return null;
    }
    return state.index.find(machine => machine.machine_id === id) || { empty: true };
  },
}

// actions
const actions = {
  /*
  loadStopcodesForSelectedUncoded({ rootState, dispatch }) {
    let unique = [...new Set(rootState.uncoded.selected.map(item => item.machine_id))];
    let promises = [];
    for (let i = 0; i < unique.length; i++) {
      let machine_id = unique[i];
      promises.push(dispatch('loadStopcodesForMachine', { machine_id }));
      promises.push(dispatch('loadTouchConfigForMachine', { machine_id }));
    }
    return Promise.all(promises);
  },
  */

  loadMachines({ commit, dispatch, rootState }) {
    return new Promise((resolve, reject) => {
      axios.get("mobile/status").then(({ data }) => {
        console.log(data);
        commit("setMachines",data);
        resolve();
      }).catch((err) => {
        reject(err);
      })
    });
  },
}

// mutations
const mutations = {
  setMachines(state, data) {
    state.index = data;
    state.loaded = true;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}