<template>
  <i class="fas fa-spinner fa-spin"></i>
</template>
<script>
export default {
  props: {
    step: {
      default: null,
    },
    maxStep: {
      default: null,
    },
  },
};
</script>