<template>
    <div>
        <div v-if="data.work_order_no !== '-'">
        <h1>{{data.part_no}}</h1>
        <p>{{data.part_description}}</p>
        </div>
        <div v-else>
            <p>-</p>
        </div>
    </div>
</template>
<script>
export default {
    props:[
        'data'
    ]
}
</script>
<style scoped lang="scss">
    h1,p{
        margin:0;padding:0;
        text-align:left;
    }
    h1{
        font-size:20px;
        font-weight:bold;
        @media screen and (max-width:600px){
            font-size:14px;
        }
    }
    p{
        font-size:11px;
        white-space: nowrap;
    }
</style>