<template>
    <div>
        <div v-if="data.work_order_no !== '-'">
        <h1>{{data.card_good_qty}}</h1>
        <p>{{data.work_order_qty}}</p>
        </div>
        <div v-else>
            <p>-</p>
        </div>
    </div>
</template>
<script>
export default {
    props:[
        'data',
        'value'
    ]
}
</script>