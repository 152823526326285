<template>
    <div class="boolean">
        <i class="far fa-check-square" v-if="value"></i>
        <i class="far fa-square" v-else></i>
    </div>
</template>
<script>

export default {
    props:[
        'data',
        'value',
        'config'
    ],
    computed:{
        d(){
        }
    }
}
</script>

<style scoped>
.boolean .far{
    font-size:30px;
}
</style>