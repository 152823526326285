<template>
  <div style="text-align: center; color: red; font-size: 24px">
    <i class="fas fa-exclamation-triangle" v-if="showWarning"></i>

    <span class="fa-stack fa-1x" style="color: red; font-size: 20px" v-if="showNoOrderWarning">
      <i class="fas fa-siren-on fa-stack-1x fade-in-out"></i>
      <i class="fas fa-siren fa-stack-1x"></i>
    </span>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: ["data", "value", "config"],
  computed: {
    ...mapGetters({
      machineHasSf: "machines/machineHasSf",
    }),
    showWarning() {
      return this.data.last_id_key === "drec";
    },
    hasSf141(){
      return this.machineHasSf(this.machine_id,"141");
    },
    machine_id(){
      return this.data.machine_id;
    },
    showNoOrderWarning(){
      return this.data.status !== 'S' && !this.hasSf141 && this.data.work_order_no === '-';
    }
  },
};
</script>
<style scoped>
.fade-in-out {
  animation: fade 1s linear infinite;
}
@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
</style>