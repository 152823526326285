<template>
  <div>
    <nav class="navbar main-nav navbar-dark bg-dark fixed-top">
      <div class="container-fluid">
        <button class="navbar-toggler" type="button" @click="toggleNav">
          <img
            class=""
            src="@/assets/Balthzar.png"
            alt=""
            style="max-height: 40px"
          />
        </button>
      </div>
    </nav>
    <div class="spacer" style="height: 44px"></div>
    <div class="container-xxxl">
      <slot></slot>
    </div>

  </div>
</template>
<script>
import SideMenu from "./submenu/SideMenu";
import { loadLanguageFromCache } from "@/i18n";
import { useRoute } from "vue-router";
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import { watch, ref } from "vue";
export default {
  setup() {
    const route = useRoute();

    const title = ref(route.meta.title);
    const description = ref("");
   
    return {
      title,
      description,
    };
  },
  created() {
    setInterval(()=>{store.dispatch('status/loadMachines')},30*1000);
  },
  mounted() {
  },
  data() {
    return {};
  },
  computed: {
   
  },
  methods: {
  
  },
  components: {
  },
};
</script>
<style lang="scss">
</style>