<template>
    <div>
        {{cycles_used}}
    </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
    props:[
        'data'
    ],
    computed:{
        ...mapState({
            period: state => state.config.periods.machines
        }),
        cycles_used(){
            switch(this.period){
                case "shift":
                    return this.data.current_shift_cycles;
                    break;
                case "24h":
                    return this.data.cycles_24h;
                    break;
                case "today":
                    return this.data.today_cycles;
                    break;
            }
        }
    }
}
</script>