import { execute_json } from "@/helpers/requests";
import axios from "@/requests/axios";

const state = () => ({
   

});

// getters
const getters = {
    
}

// actions
const actions = {
 

}
const mutations = {
    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}