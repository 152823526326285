<template>
    <div>
        <i class="fas" :class="status"></i> 
    </div>
</template>
<script>
export default {
    props:[
        'data'
    ],
    computed:{
        status(){
            if (this.data.status?.toLowerCase() === 'cancelled'){
                return "fa-times"
            }else{
                return "fa-check"
            }
        }
    }
}
</script>