<template>
   <div :class="config.class">
    <h1>{{value}}</h1>
  </div>
</template>

<script>
export default {
  props: ["data", "value", "config"],
  data() {
    return {};
  },
  computed: {},
  methods:{
     
  }
};
</script>

<style>
</style>