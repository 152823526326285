<template>
    <div>
        <h1>{{data.stopDescription}}</h1>
        <p>{{data.currentStopReason}} </p>
    </div>
</template>
<script>
export default {
    props:[
        'data'
    ],
    computed:{
    }
}
</script>