<template>
    <div class="b-text-long">
        {{value}}
    </div>
</template>
<script>

export default {
    props:[
        'data',
        'value',
        'config'
    ],
    computed:{
        d(){
        }
    }
}
</script>

<style scoped>
.b-text-long{
    width:350px;
}
</style>