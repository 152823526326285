<template>
  <div>
    <button class="btn" @click="openOrderDocumentPopup">
      <i class="fas fa-file-alt"></i>
    </button>
  </div>
</template>
<script>
import { mapState } from "vuex";
import axios from "@/requests/axios";
import store from "@/store";
import { execute_json } from "@/helpers/requests";
export default {
  props: ["data", "config"],
  data() {
    return {};
  },
  computed: {
    ...mapState({
      settings: (state) => state.auth.settings,
    }),
  },
  methods: {
    openOrderDocumentPopup() {
      store.commit("popups/addPopup", {
        type: "order-documents",
        canClose: true,
        data: this.data,
      });
    },
  },
};
</script>