Date.prototype.toBalthzarTime = function () {
    return this.toLocaleString("sv-SE", { timezone: "UTC" });
}

function daybreakDate(d, daybreak) {
    //console.log("daybreaking", daybreak, d);
    let hourcomponent = daybreak.slice(11, 19);
    let daytimeNow = d.toBalthzarTime().split(" ");
    //console.log("comparing daybreak",hourcomponent,daytimeNow[1]);
    if (hourcomponent > daytimeNow[1]) {
        d.setDate(d.getDate() - 1);
        daytimeNow = d.toBalthzarTime().split(" ");
    }
    let daycomponent = daytimeNow[0];
    //console.log("daybreaking!!", hourcomponent, daycomponent,daytimeNow);
    return daycomponent + " " + hourcomponent.split(".")[0];
}

function secondsToHhMm(seconds) {
    let sign = "";
    if (seconds == "" || seconds == null || seconds == undefined || isNaN(seconds)) {
        seconds = 0;
    }
    if (seconds < 0) {
        seconds *= -1;
        sign = "-"
    }
    let hh = Math.floor(seconds / 3600);
    let hourLess = seconds - hh * 3600;
    let mm = Math.floor(hourLess / 60);
    return sign + hh + ":" + ("" + mm).padStart(2, "0");
    return sign + new Date(seconds * 1000).toISOString().substr(11, 5);
}

function shortDateTime(date) {
    let timestamp = Date.parse(date);
    if (isNaN(timestamp) == false) {
        let date = new Date(timestamp).toBalthzarTime();
        return date.slice(5, 10) + " " + date.slice(11, 16);
        let d = new Date(timestamp).toISOString();
        const [year, ...rest] = d.split("-");
        let yearless = rest.join("-");
        const [dateOut, time] = yearless.split("T");
        const [hours, minutes, seconds] = new Date(timestamp).toBalthzarTime().split(":"); // should be checked
        return dateOut + " " + [hours, minutes].join(":");
    } else if (typeof date === "string") {
        return date.slice(5, 10) + " " + date.slice(11, 16);
    } else {
        return date;
    }
}

function hoursToHhMm(hours) {
    let hh = ~~(hours); //functionally identical to Math.trunc
    let mm = ("" + Math.abs(Math.round((hours % 1) * 60))).padStart(2, "0");
    return `${hh}:${mm}`;
}

function getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    d.setHours(0); d.setMinutes(0); d.setSeconds(0); d.setDate(diff);
    return new Date(d);
}

function getNowDate(days) {
    let d = new Date();
    if (days !== undefined) {
        d.setDate(d.getDate() + days);
    }
    return d;
}

function getNow(days) {
    return getNowDate(days).toBalthzarTime();
}

function daysBackDate(date, days) {
    let d = new Date(date);
    if (days !== undefined) {
        d.setDate(d.getDate() + days);
    }
    return d;
}

function daysBack(date, days) {
    return daysBackDate(date, days).toBalthzarTime();
}

function getNowStart(days) {
    let d = getNowDate(days);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    return d.toBalthzarTime();
}


function getNowShort(days) {
    let now = getNow(days);
    return now.slice(5, 10) + " " + now.slice(11, 16);
}

function getStartAndEndDate(horizon) {
    let end = getNowDate();
    let start = getNowDate(-1 * 365);
    let date = new Date();
    switch (horizon) {
        case "2 years":
            start = getNowDate(-2 * 365);
            break;
        case "last year":
            //var date = new Date();
            start = new Date(date.getFullYear()-1,0,1);
            end = new Date(date.getFullYear(),0,0);
            break;
        case "1 year":
            start = getNowDate(-1 * 365);
            break;
        case "this year":
            //var date = new Date();
            start = new Date(date.getFullYear(),0,1);
            break;
        case "120 days":
            start = getNowDate(-120);
            break;
        case "90 days":
            start = getNowDate(-90);
            break;
        case "60 days":
            start = getNowDate(-60);
            break;
        case "30 days":
            start = getNowDate(-30);
            break;
        case "this month":
            //var date = new Date();
            start = new Date(date.getFullYear(), date.getMonth(), 1);
            break;
        case "last month":
            //var date = new Date();
            end = new Date(
                new Date(date.getFullYear(), date.getMonth(), 1) - 1
            );
            start = new Date(end.getFullYear(), end.getMonth(), 1);
            break;
        case "2 months":
           // var date = new Date();
            start = new Date(date.getFullYear(), date.getMonth() - 1, 1);
            break;
        case "14 days":
            start = getNowDate(-14);
            break;
        case "last 2 weeks":
            start = getMonday(getNowDate(-7));
            break;
        case "last week":
           // var date = new Date();
            start = getMonday(getNowDate(-7));
            end = daysBackDate(start,6);
            break;
        case "this week":
            start = getMonday(date);
            break;
        default:
            break;
    }
    return { end, start }
}



export { getStartAndEndDate, daysBackDate, daysBack, daybreakDate, secondsToHhMm, shortDateTime, hoursToHhMm, getMonday, getNow, getNowStart, getNowDate, getNowShort }