<template>
  <div
    class="indicator status-small"
    :class="status"
    style="text-align: center; line-height: 200%"
  >
    <span>{{data.uncoded_stops_shift24h}}</span>
  </div>
</template>
<script>
export default {
  props: ["data", "config"],
  computed: {
    status() {
      return this.data.uncoded_stops_shift24h > 1
        ? "c-red"
        : this.data.uncoded_stops_shift24h == 1
        ? "c-yellow"
        : "c-green";
    },
  },
};
</script>