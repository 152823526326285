import axios from 'axios';
import { Notification } from '@/helpers/notifications';

const isProduction = process.env.NODE_ENV === 'production' && !window.Cypress;

import store from '@/store';
import _ from "lodash";
//import { config } from 'cypress/types/bluebird';
//import { i18n } from "@/i18n.js";

const instance = axios.create({
    baseURL: isProduction ? "/v1/api" : "https://localhost:7271/api",
    //withCredentials: true,
    headers: {
        "accept": '*/*',
        "content-type": "application/json",
       // "connection": "keep-alive"
    }
})


instance.interceptors.request.use(request => {
    //console.log(request);
    if  (store.state.auth.token){
        request.headers['Authorization'] = `Bearer ${store.state.auth.token}`;
        //request.withCredentials = true;
    }
    return request;
}, error => {
    return Promise.reject(error);
});

instance.interceptors.response.use(response => {
    return response;
}, error => {
    const statusCode = error.response ? error.response.status : null;
    if (statusCode === 404) {
        //notifier.error('The requested resource does not exist or has been deleted')
       window.location.reload();
    }

    if (statusCode === 401) {
       // notifier.error('Please login to access this resource')
       window.location.reload();
    }
    return Promise.reject(error);
});

export default instance;