<template>
  <div class="indicator status-small" :class="status" v-if="status">
    <i class="fas fa-running centered"></i>
  </div>
</template>
<script>
export default {
  props: ["data"],
  computed: {
    hasPrevOp() {
      return (
        this.data.prev_op_card_no &&
        this.data.prev_op_card_no != 0 &&
        this.data.prev_op_card_no != null
      );
    },
    status() {
      if (!this.hasPrevOp) {
        return "c-gray";
      } else {
        if (this.data.prev_op_qty >= this.data.planned_op_qty){
          return 'c-green'
        }else if (this.data.prev_op_qty > 0){
          return 'c-yellow'
        }else{
          return 'c-red'
        }
      }
    },
  },
};
</script>