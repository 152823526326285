<template>
  <div :class="Class" class="indicator t-small">
    <span v-if="valueIsNumerical">{{ valueTrimmed }}</span>
    <span v-else>NaN</span>
  </div>
</template>
<script>
import { hoursToHhMm } from "@/helpers/dates";
export default {
  props: [
    "data",
    "value", // {numerical}
    "config", // {precision,greenLimit,yellowLimit,grayValue,limitType[hard,range],clampAround, trimValue}
  ],
  computed: {
    precision() {
      return this.config.precision || 1;
    },
    limitType() {
      return this.config.limitType || "hard";
    },
    greenLimit() {
      let limit = this.config.greenLimit;
      let greenLimit = this.data[limit];
      if (greenLimit !== undefined && !isNaN(greenLimit)) {
        return +greenLimit;
      } else {
        return +limit;
      }
    },
    yellowLimit() {
      let limit = this.config.yellowLimit;
      let yellowLimit = this.data[limit];
      if (yellowLimit !== undefined && !isNaN(yellowLimit)) {
        return +yellowLimit;
      } else {
        return +limit;
      }
    },
    grayValue() {
      let limit = this.config.grayValue || undefined;
      if (isNaN(limit)) {
        return !isNaN(this.data[limit]) ? +this.data[limit] : 0;
      }
      return limit;
    },
    clampAround() {
      return isNaN(this.config.clampAround) ? 100 : this.config.clampAround;
    },
    transformTo() {
      return this.config.transformTo || "fixed";
    },
    trimValue() {
      return this.config.trimValue || true;
    },
    valueIsNumerical() {
      return !isNaN(this.value);
    },
    valueNum(){
        if (this.valueIsNumerical){
            return +this.value;
        }
        return -1;
    },
    valueFixed() {
      if (this.valueIsNumerical && this.transformTo === "fixed") {
        return (+this.value).toFixed(this.precision);
      }
      if (this.valueIsNumerical && this.transformTo === "hh:mm") {
        return hoursToHhMm(this.value);
      }
      return -1;
    },
    valueTrimmed() {
      if (this.trimValue && this.valueFixed > 100) {
        return Math.round(this.valueFixed);
      }
      return this.valueFixed;
    },
    boxClass() {
      if (this.limitType === "hard") {
        if (this.valueNum == this.grayValue) {
          return "c-gray";
        } else if (this.valueNum >= this.greenLimit) {
          return "c-green";
        } else if (this.valueNum >= this.yellowLimit) {
          return "c-yellow";
        }
        return "c-red";
      }
      if (this.value == this.grayValue) {
        return "c-gray";
      } else if (
        this.valueNum >= this.clampAround - this.greenLimit &&
        this.valueNum <= this.clampAround + this.greenLimit
      ) {
        return "c-green";
      } else if (
        this.valueNum >= this.clampAround - this.yellowLimit &&
        this.valueNum <= this.clampAround + this.yellowLimit
      ) {
        return "c-yellow";
      }
      return "c-red";
    },
    Class(){
        return this.boxClass + " " +this.config.class
    }
  },
};
</script>