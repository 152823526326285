<template>
  <div :class="t" class="indicator t-small">
    {{ k_used }}
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: ["data"],
  computed: {
    ...mapState({
      period: (state) => state.config.periods.machines,
    }),
    k_used() {
      switch (this.period) {
        case "shift":
          return this.data.k_percent_shift;
          break;
        case "24h":
          return this.data.k_percent_24;
          break;
        case "today":
          return this.data.k_percent_today;
          break;
        case "calendar":
          return this.data.k_percent_calendar;
          break;
      }
    },
    t() {
      if (+this.k_used === 0) {
        return "c-gray";
      } else if (+this.k_used >= +this.data.q_green_limit) {
        return "c-green";
      } else if (+this.k_used >= +this.data.q_yellow_limit) {
        return "c-yellow";
      } else return "c-red";
    },
  },
};
</script>