<template>
    <div>
        <span class="nosplit">{{data.adjusted_start_time ? data.adjusted_start_time : data.mintime}}</span><br>
        <span class="nosplit">{{data.maxtime}}</span>
    </div>
</template>
<script>
export default {
    props:[
        'data',
        'value'
    ]
}
</script>