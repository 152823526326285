<template>
  <div class="indicator status-small c-white" >
      <i class="fas centered" :class="status" ></i>
  </div>
</template>
<script>
export default {
  props: ["data"],
  computed: {
    status() {
      switch (+this.data.cancelled) {
        case 0:
          return "fa-running";
        case 1:
          return "fa-flag";
      
      }
    },
  },
};
</script>