import { createRouter, createWebHistory } from 'vue-router'
import defaultLayout from '@/layouts/DefaultLayout'
import simpleLayout from '@/layouts/SingleCard'
import Home from "@/views/Home.vue";
import LoginForm from "@/views/auth/login-form.vue";

import store from '@/store'

function loadView(view) {
  return () => import(/* webpackChunkName: "[request]" */ `@/views/${view}.vue`)
}
function loadComponent(view) {
  return () => import(/* webpackChunkName: "[request]" */ `@/components/${view}.vue`)
}

const routes = [
  {
    path: "/",
    name: "bzx-status",
    meta: {
      requiresAuth: true,
      layout: defaultLayout,
      title: "Bzx Status"
    },
    component: Home
  },
  {
    path: "/login",
    name: "login-form",
    meta: {
      requiresAuth: false,
      layout: simpleLayout,
      title: "Sign In"
    },
    component: LoginForm
  },
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    //return { y : 0 }
    return { top: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  console.log(to,from);
  if (to.name === "login-form" && store.state.auth.token) {
    next({ name: "bzx-status" });
    
  }
  else if (to.meta.requiresAuth) {
    console.log(to,store.state.auth.token);
    if (!store.state.auth.token) {
      next({
        name: "login-form"
      });
    } else {
      store.dispatch('status/loadMachines')
        .then(() => {
          next();
        })
    }
  } else {
    next();
  }
});

export default router
