<template>
  <div :class="config.class">
    <h1 :class="{ 'c-red': red }">{{ val1 }}</h1>
    <p>{{ val2 }}</p>
  </div>
</template>
<script>
import { shortDateTime, secondsToHhMm } from "@/helpers/dates";
export default {
  props: ["data", "value", "config"],
  computed: {
    red() {
      if (this.config.compDates) {
        if (
          new Date(this.data[this.config.val1]).getTime() < new Date().getTime()
        ) {
          return true;
        }
      }
    },
    val1() {
      return this.valueForNum(1);
    },
    val2() {
      return this.valueForNum(2);
    },
    shortDateTime() {
      return shortDateTime;
    },
  },
  methods: {
    valueForNum(num) {
      let col = this.config["val" + num];
      let val = this.data[col];
      if (this.config["format" + num]) {
        if (this.config["format" + num] === "hhmm") {
          return secondsToHhMm(val);
        }

        if (this.config["format" + num] === "flexdec") {
          let parsed = parseFloat(val);
          if (parsed >= 100 || parsed % 1 === 0) {
            return Math.round(val);
          }
          if (parsed >= 10) {
            return parsed.toFixed(1);
          }
          return parsed.toFixed(2);
        }
        if (this.config["format" + num] === "list") {
          return val instanceof Array ? val.sort().join(", ") : val;
        }
      }
      return this.config.dates
        ? this.config.short
          ? shortDateTime(val)
          : new Date(val).toLocaleString()
        : val;
    },
  },
};
</script>
<style scoped>
h1.c-red {
  color: Red;
}
</style>