<template>
    <div>
        <span class="nosplit">{{d}}</span>
    </div>
</template>
<script>
import {shortDateTime} from "@/helpers/dates";

export default {
    props:[
        'data',
        'value',
        'config'
    ],
    computed:{
        d(){
            let toFixed = 0;
            if (this.config && this.config.fixedTo){
                toFixed = this.config.fixedTo;
            }
            return (+this.value).toFixed(toFixed);
        }
    }
}
</script>