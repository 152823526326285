<template>
  <div v-if="goalType !== null">
    <span>{{ $t(translations[goalType]) }}:{{data[goalType]}}</span>
  </div>
</template>

<script>
export default {
  props: ["data", "value", "config"],
  data() {
    return {
      targets: [
        "target_good_qty",
        "target_good_qty_alt_unit",
        "target_earned_hours",
      ],
      translations:{
        "target_good_qty":"1304",
        "target_good_qty_alt_unit":"6286",
        "target_earned_hours":"3898",
      }
    };
  },
  computed: {
    goalType() {
      let type = null;
      this.targets.forEach((t) => {
        if (this.data[t] !== null) {
          type = t;
          return type;
        }
      });
      return type;
    },
  },
};
</script>

<style>
</style>