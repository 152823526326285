import axios from "@/requests/axios";
import { setStorage } from "../../helpers/storage";

const state = () => ({
  messages:{
    "ENG":{
    },
    "SWE":{
    },
    "GER":{
    }
  },
  loadedLanguages:[],
  defaultColumns:{

  },
  defaultColumnsLoaded:false
});

// getters
const getters = {
 
}

// actions
const actions = {
    loadLanguage({commit,rootState},language){
      return new Promise((resolve,reject) => {
        axios.get("translations")
        .then(({data})=>{
          console.log("incoming language",language,data)
              commit('setLanguage',{language,data});
              resolve();
            })
            .catch((err)=>{
                reject(err);
            })
        });
    },
    loadDefaultColumns({commit,state}){
      return new Promise((resolve,reject) => {
        if (state.defaultColumnsLoaded){
          resolve();
        }else{
          axios({url:"json/default_translations.json",baseURL:"/"})
          .then(({data})=>{
            commit("setDefaultColumns",data);
            resolve();
          });
        }
      });
    }
}

// mutations
const mutations = {
    setLanguage(state,payload){
      //console.log(payload);
      state.messages[payload.language] = payload.data; 
      state.loadedLanguages.push(payload.language);
      setStorage("lang_cache_"+payload.language,payload.data);
    },
    setDefaultColumns(state,data){
      state.defaultColumns = data;
      state.defaultColumnsLoaded = true;
    }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}