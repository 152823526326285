<template>
  <div class="hover-wrap">
    <div
      :class="t"
      class="indicator t-small"
      @mouseenter="startHover"
      @mouseleave="stopHover"
    >
      {{ a_fixed }}
    </div>
    <div class="hover-field" v-if="hovered">
      <loading v-if="loadedData == false" />
      <div v-else>
        <b-table
          :data="historyForCardNo?.slice(0, 5)"
          :columnConfig="{
            stop_date: {
              component: 'date',
              config: {
                short: true
              },
            },
            machine_id: {},
            a_percent: {
              label: 'A%',
              component: 'color-box',
              config: {
                greenLimit: 'speed_deviation_green_limit',
                yellowLimit: 'speed_deviation_yellow_limit',
                clampAround: true,
                valueIsNumerical: true,
                limitType: 'hard',
              },
            },
            planned_cycle_time: {
              label: 'P',
            },
            real_cycle_time: {
              label: 'R',
            },
          }"
        ></b-table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import store from "@/store";
export default {
  props: ["data"],
  data() {
    return {
      hoverDelay: null,
      hovered: false,
      loadedData: false,
    };
  },
  methods: {
    startHover() {
      console.log("mouse-in");
      this.hoverDelay = setTimeout(
        (() => {
          this.hovered = true;
        }).bind(this),
        500
      );
    },
    stopHover() {
      console.log("mouse-out");
      clearTimeout(this.hoverDelay);
    },
  },
  computed: {
    ...mapState({
      period: (state) => state.config.periods.machines,
      cardHistory: (state) => state.parts.cardHistory,
    }),
    historyForCardNo() {
      return this.cardHistory[this.data.part_no]?.[this.data.op_number]
        .filter((h) => h.prod_qty > 0.5 * h.planned_qty)
        .sort((a, b) => {
          return a.stop_date < b.stop_date ? 1 : -1;
        });
    },
    a_used() {
      switch (this.period) {
        case "shift":
          return this.data.a_percent_shift;
          break;
        case "24h":
          return this.data.a_percent_24;
          break;
        case "today":
          return this.data.a_percent_today;
          break;
        case "calendar":
          return this.data.a_percent_calendar;
          break;
      }
      return this.data.a_percent_shift;
    },
    a_fixed() {
      if (this.a_used > 100) {
        return Math.floor(this.a_used);
      }
      return this.a_used;
    },
    t() {
      if (+this.a_used === 0) {
        return "c-gray";
      } else if (
        +this.a_used >= 100 - this.data.speed_deviation_green_limit &&
        (this.data.speed_over_100 ||
          +this.a_used <= 100 + this.data.speed_deviation_green_limit)
      ) {
        return "c-green";
      } else if (
        +this.a_used >= 100 - this.data.speed_deviation_yellow_limit &&
        (this.data.speed_over_100 ||
          +this.a_used <= 100 + this.data.speed_deviation_yellow_limit)
      ) {
        return "c-yellow";
      } else return "c-red";
    },
  },
  watch: {
    hovered() {
      if (this.hovered) {
        store.dispatch("parts/cardHistoryForPart", this.data).then((data) => {
          this.loadedData = data;
        });
      }
    },
    p_order(){
      //TODO
      this.hovered = false;
    }
  },
};
</script>