<template>
  <form class="login-form" @submit.prevent="onSubmit">
    <p>Version: {{ version }}</p>
    <label for="inputCompany">Company</label>
    <input
      type="text"
      id="inputCompany"
      class="form-control"
      placeholder="Company_id"
      v-model="formData.company_id"
      required
    />
   
    <label for="inputUser">Pincode</label>
    <input
      type="text"
      id="inputUser"
      class="form-control"
      placeholder="Pincode"
      v-model="formData.pincode"
      required
      autofocus
    />
    <br/>
    <button
      class="w-100 btn btn-lg btn-primary"
      type="submit"
      :disabled="loading"
    >
      <Loading v-if="loading" /><span v-else>Log in</span>
    </button>
  </form>
</template>

<script>
import { reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Notification } from "@/helpers/notifications";
import { mapState } from "vuex";
import store from "@/store";
import { loadLanguageAsync, loadLanguageFromCache } from "@/i18n";
import axios from "axios";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    const last_company = localStorage.getItem("last_company");

    const formData = reactive({
      username: "",
      pincode: "",
      company_id: last_company || "",
      rememberMe: true,
    });
    const loading = ref(false);

    //loadLanguageFromCache();

    async function onSubmit() {
      const { username, pincode, rememberMe, company_id } = formData;
      loading.value = true;
      store
        .dispatch("auth/login", { username, pincode, rememberMe, company_id })
        .then(() => {
          console.log("success");
          //loadLanguageAsync(store.state.auth.lang_code);
          loadLanguageAsync("EN");
          router.push(
           "/"
          );
        })
        .catch((e) => {
          console.log("error", e);
         
          loading.value = false;
        });
    }

    return {
      formData,
      loading,
      // onCreateAccountClick,
      onSubmit,
    };
  },

  created() {
  
  },
  data() {
    return {
      predefinedCompanyId: false,
      acceptedIds: null,
    };
  },
  computed: {
    ...mapState({
      //last_company: (state) => state.auth.last_company,
      version: (state) => state.version,
    }),
  },
  components: {},
};
</script>

<style lang="scss">
</style>
