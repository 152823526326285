<template>
  <div class="home">
    <b-table :data="status" :columnConfig="config"> </b-table>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
export default {
  name: "Home",
  created() {},
  data() {
    return {
      config: {
        status: {
          component: "status",
          label: "S",
          class: "minimal",
          pre: true,
        },
        machine_id: {
          component: "machineId",
          tlabel: "1",
          label: "Machine",
          pre: true,
        },
        cycles: {
          tlabel: "2228",
          pre: true,
        },
        departmentDescription: {
          tlabel: "2",
          pre: true,
        },
        current_stop_reason: {
          tlabel: "2322",
          component: "stopcode",
          pre: true,
        },
        speed_double:{
          label:this.$t('1192'),
          component:"double-row",
          config:{
            val1:"realSpeed",
          }
        },
        speed_double2:{
          label:this.$t('1193'),
          component:"double-row",
          config:{
            val1:"plannedSpeed"
          }
        },
        cycles_custom: {
          label: "Σ " + this.$t("2228"),
          pre: true,
          key:"sumofcycles",
        },
         runtime_custom: {
          label: "Σ " + this.$t("951"),
          pre: true,
          key:"sumofrunTime",
          component:"hhMm"
        },
         stop_custom: {
          label: "Σ " + this.$t("92"),
          pre: true,
          key:"sumofstop",
        },
         stoptime_custom: {
          label: "Σ " + this.$t("463"),
          pre: true,
          key:"sumofstopTime",
          component:"hhMm"
        },
        /*
        T: {
          tlabel: "755",
          component: "tPercent",
          class: "minimal",
          pre: true,
        },
        A: {
          tlabel: "1898",
          component: "aPercent",
          class: "minimal",
          pre: true,
        },
        K: {
          tlabel: "1639",
          component: "kPercent",
          class: "minimal",
          pre: true,
        },
        TAK: {
          tlabel: "1640",
          component: "takPercent",
          pre: true,
        },
        work_order_no: {
          tlabel: "24",
          pre: true,
        },
        article: {
          component: "b-article",
          tlabel: "107",
          pre: true,
        },
        primakass: {
          component: "primakass",
          tlabel: "574",
          pre: true,
        },
        K2: {
          tlabel: "1639",
          component: "kPercent",
          class: "minimal",
          pre: true,
        },
        TAK2: {
          tlabel: "1640",
          component: "takPercent",
          pre: true,
        },
        work_order_no2: {
          tlabel: "24",
          pre: true,
        },
        article2: {
          component: "b-article",
          tlabel: "107",
          pre: true,
        },
        primakass2: {
          component: "primakass",
          tlabel: "574",
          pre: true,
        },
        order_rem_hours_hh_mm: {
          tlabel: "2012",
          component: "orderRemaining",
          pre: true,
        },
        uncoded_stops_24h: {
          tlabel: "5824",
          pre: true,
          //component:"uncodedStops24h"
        },
        */
      },
    };
  },
  computed: {
    ...mapState({
      path: (state) => state.auth.path,
      status: (state) => state.status.index,
    }),
  },
  components: {},
};
</script>
