//import store from '@/store'; store is totally available

function setStorage(key, val, clear = true) {
    //check to
    if (key === "confident_time" || !clear) {
        return localStorage.setItem(key, val);
    }
    let jsonArr = JSON.stringify(val);
    let JSONCompressed = LZString.compressToUTF16(jsonArr);
    return localStorage.setItem(key, JSONCompressed);
}

function getStorageWithDefault(key, def) {
    return getStorage(key) || def;
}


function getStorage(key, clear = true) {
    if (key === "confident_time" || !clear) {
        return localStorage.getItem(key);
    }
    if (!checkStorage(key)) {
        return null;
    }
    try {
        let start = window.performance.now();
        let compressed = localStorage.getItem(key);
        let jsonArr = LZString.decompressFromUTF16(compressed);
        let timeTaken = window.performance.now() - start;
        let parsed = JSON.parse(jsonArr);
        //console.log("%c loading " + key + " out of storage took " + timeTaken + "ms", "color:white;background:black;");
        return parsed;
    } catch (e) {
        return null;
    }
}

function checkStorage(key) {
    if (localStorage.getItem(key) === null || storageTimedOut(key)) {
        return false;
    }
    return true;
}

function storageTimedOut(key) {
    if (getStorage("super_confident",false) === 'true'){
        console.log("super confident");
        return false;
    }
    let confidentTime = +localStorage.getItem("confident_time") || 0;
    let confidentInterval = 4 * 24 * 60 * 60 * 1000; //4d x 24 h/d x 60 m/h x 60 s/m x 1000 ms/s,
    if (new Date().getTime() < confidentTime + confidentInterval) {
        //we're confident
        return false;
    }
    removeStorage(key);
    return true;
}

function removeStorage(key) {
    return localStorage.removeItem(key);
}

async function digestMessage(message) {
    const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
    return hashHex;
}

export { setStorage, getStorage, getStorageWithDefault, checkStorage, removeStorage, digestMessage };