<template>
  <div>
    {{ uncoded_value }} <span v-if="has85">24h</span><span v-else>shift</span>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["data"],
  computed: {
    ...mapGetters({
      machineHasSf: "machines/machineHasSf",
    }),
    has85() {
      return this.machineHasSf(this.data.machine_id, "85");
    },
    uncoded_value() {
      return this.has85
        ? this.data.uncoded_stops_24h
        : this.data.uncoded_stops_shift24h;
    },
  },
};
</script>