<template>
    <div class="b-text-short">
        {{value}}
    </div>
</template>
<script>

export default {
    props:[
        'data',
        'value',
        'config'
    ],
    computed:{
        d(){
        }
    }
}
</script>

<style scoped>
.b-text-short{
    width:150px;
}
</style>