<template>
    <div>
         <div v-if="data.work_order_no !== '-'">
            <h1>{{data.order_rem_hours_hh_mm}}</h1>
            <p>{{order_finished_formatted}}</p>
        </div>
        <div v-else>
            <p>-</p>
        </div>
    </div>
</template>
<script>
import {shortDateTime} from "@/helpers/dates"
export default {
    props:[
        'data',
        'value',
        'config'
    ],
    computed:{
        order_finished_formatted(){
            return shortDateTime(this.data.order_finished);
        }
    }
}
</script>