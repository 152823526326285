<template>
    <div>
        <h1>{{data.lineNo !== undefined && data.lineNo != "-"  ? data.lineNo+" - " : ""}}{{data.machineId}}</h1>
        <p>{{data.objectDescription}}</p> 
    </div>
</template>
<script>
export default {
    props:[
        'data'
    ]
}
</script>