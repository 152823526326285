<template>
    <span class="nosplit">
        {{value}}
    </span>
</template>
<script>

export default {
    props:[
        'data',
        'value',
        'config'
    ],
    computed:{
        d(){
        }
    }
}
</script>

<style scoped>
</style>