<template>
    <div class="indicator status-small" :class="status+' c-'+data.stopReasonColor?.toLowerCase()">
        
    </div>
</template>
<script>
export default {
    props:[
        'data'
    ],
    computed:{
        status(){
            if (this.data.status === 'S'){
                if (this.data.currentStopReason === '-'){
                    return "c-uncoded";
                }else{
                    return "c-red";
                }
            }else{
                return "c-green"
            }
        }
    }
}
</script>