<template>
  <div
    class="indicator status-small"
    :class="status"
    style="text-align: center; line-height: 200%"
  >
  <router-link v-if="this.filteredBySelection.length" :to="runActivityLink">
    {{filteredBySelection.length}}
  </router-link>
    <span v-else>-</span>
  </div>
</template>
<script>
import { Pulse } from "@/helpers/heartbeat";
import { mapState } from "vuex";
export default {
  props: ["data", "config"],
  data() {
    return {
      pulse: null,
    };
  },
  mounted() {
    if (this.data?.machine_id == undefined) {
      //error
    }
    //pulse on -> activities/loadActivitiesForSelectionOwner,{selectionOwner:auth.state.user}
    this.pulse = new Pulse({
      action: "activities/loadActivitiesForSelectionOwner",
      interval: 60,
      primaryKey: "selectionOwner",
      parameters: { selectionOwner: this.user },
    });
  },
  beforeUnmount() {
    //pulse off
    this.pulse.stop();
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.username,
      activitiesIndex: (state) => state.activities.index,
      lastLoc: (state) => state.sidebar.lastLocation,
      lastLocType: (state) => state.sidebar.lastLocationType,
    }),
    activities() {
      return this.activitiesIndex[this.user] || [];
    },
    filteredForMachine() {
      return this.activities.filter((activity) => {
        return activity.machine_id == this.data.machine_id;
      });
    },
    filteredBySelection() {
      if (this.config?.selection !== undefined && this.config.selection != "" && this.config.selection != "all") {
        return this.filteredForMachine.filter(
          (activity) => activity.type_description?.toLowerCase() == this.config.selection?.toLowerCase()
        );
      }
      return this.filteredForMachine;
    },
    status() {
      return this.filteredBySelection.length > 1
        ? "c-red"
        : this.filteredBySelection.length == 1
        ? "c-yellow"
        : "c-green";
    },
    runActivityLink() {
      let [empty, viewcode, ...rest] = this.$route.path.split("/");
      if (
        ["all-machines", "files", "statistics"].includes(viewcode) ||
        viewcode === undefined
      ) {
        viewcode = "machines";
      }
      return (
        "/" +
        viewcode +
        "/" +
        (this.lastLocType
          ? this.lastLocType
          : this.$route.params.locationtype) +
        "/" +
        (this.lastLoc ? this.lastLoc : this.$route.params.location_statistics) +
        "/run-activity/" +
        this.data.machine_id
      );
    },
  },
};
</script>