<template>
  <div>
      <i class="fas fa-clock" v-if="value == '0'"></i>
      <i class="fas fa-user" v-if="value == '1'"></i>
      </div>
</template>

<script>
export default {
  props: ["data", "value"],
};
</script>

<style>
</style>