import axios from "@/requests/axios";

const execute_json = (function_name, json_data = {}, config = {}) => {
    return new Promise((resolve, reject) => {
        axios.post("balthzar_execute_json_out", {
            function_name,
            json_data
        }, config).then((data) => {
            resolve(data);
        }).catch((err) => {
            console.warn(err);
            reject(err);
        });
    });
};

export { execute_json }