<template>
  <div class="c-table">
    <b-table
      :data="filteredData"
      :column-config="columnConfig"
      :sort-key="sortKey"
      :sort_dir="sortDir"
      :primary-key="primaryKey"
      :single-select="singleSelect"
      :selectable="selectable"
      :deselect="deselect"
      :show-headers="showHeaders"
      :caption="caption"
      :searchable="usesSearch"
      :table-type="tableType"
      :initial-high="initialHigh"
      :searchfield="searchKey"
      :colors="colors"
      :advanced="advanced"
      :grouped-by="groupedBy"
      :subOpen="subOpen"
      @selectionChanged="selectionChanged"
      @group="changeGrouping"
      @sort="changeSorting"
      @filter="changeFilter"
    ></b-table>
    <teleport :to="teleportTarget" :disabled="!teleport" v-if="teleport && readyToTeleport">
      <li v-if="canUser('can-access-admin-panel')">
        <router-link
          v-if="overviewId == -1"
          class="dropdown-item"
          :to="'/admin/config/tables/' + configString"
          ><i class="fas fa-table"></i>
          {{ configString.replace(/_/g, " ") }}</router-link
        >
        <router-link
          v-if="overviewId != -1"
          class="dropdown-item"
          :to="'/admin/config/overview/' + overviewId"
          ><i class="fas fa-table"></i>
          {{ configString.replace(/_/g, " ") }}</router-link
        >
      </li>
      <li class="multicolumn-li">
        <div class="dropdown-item" @click="toggleSubOpen">
          <i class="fas fa-cog"></i>
        </div>
        <div class="dropdown-item" @click="changeFilter(null, $event)">
          <i class="fas fa-filter"></i> 
        </div>
      </li>
    </teleport>
    <div
      v-if="filterPopupOpen"
      class="popup-wrap"
      @click.self="filterPopupOpen = false"
    >
      <div class="popup large">
        <filter-component
          :filter="workingFilter"
          v-if="workingFilter !== null"
          :data="data"
          :isroot="true"
        ></filter-component>
        <input
          type="checkbox"
          v-model="workingFilter.available_for_all"
          v-if="workingFilter"
        />
        &nbsp; <label for="">Available for all</label>&nbsp;
        <input type="text" v-model="filterName" /><button
          class="btn"
          @click="namedSave"
        >
          <i class="fas fa-save"></i>
        </button>
        <select v-model="namedLoad" @change="loadSaved">
          <option :value="f" v-for="f in fetchedFilters" :key="f.record_no">
            {{ f.variable_value }}
          </option>
        </select>
        <button class="btn" @click="applyFilter">apply</button>
        <button class="btn" @click="assignFilter">
          assign to template
          <div class="fas fa-user"></div>
        </button>
        <button class="btn" @click="clearFilter">
          clear template
          <div class="fas fa-times"></div>
        </button>
      </div>
    </div>
    <div v-if="activeFilter" class="filter-bar">
      <button class="btn" style="color: white" @click="removeFilter">
        <i class="fas fa-times"></i>
      </button>
      <p>
        {{ readableFilter }}
      </p>
      <select v-model="namedLoad" @change="loadSavedAndApply">
        <option :value="f" v-for="f in fetchedFilters" :key="f.record_no">
          {{ f.variable_value }}
        </option>
      </select>
      <button class="btn" @click="changeFilter(null, $event)">
        <i class="fas fa-edit"></i>
      </button>
    </div>
  </div>
</template>
<script>
import colorBox from "./items/customizable/colorBox";
import * as ItemComponents from "./items";
import FilterComponent from "@/components/FilterComponent";
import { filterFunc, readableFilter } from "@/helpers/filter";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import store from "@/store";

export default {
  props: {
    configString: String,
    data: Array,
    primaryKey: String,
    singleSelect: {
      Boolean,
      default: true,
    },
    selectable: {
      default: true,
    },
    deselect: {
      Boolean,
      default: true,
    },
    showHeaders: {
      Boolean,
      default: true,
    },
    caption: {
      String,
    },
    useIntersect: {
      Boolean,
      default: null,
    },
    defaultSortKey: {
      default: null,
    },
    defaultSortDir: {
      default: null,
    },
    defaultGroupBy:{
      default:null
    },
    searchable: {
      Boolean,
      default: false,
    },
    initialHigh: {
      default: [],
    },
    searchfield: {
      default: null,
    },
    colors: {
      default: null,
    },
    teleport: {
      default: false,
    },
    advanced: {
      default: true,
    },
    teleportTarget: {
      default: "#top-right-dropdown",
    },
    overviewId: {
      default: -1,
    },
  },
  emits: ["selectionChanged"],
  data() {
    return {
      filterPopupOpen: false,
      workingFilter: null,
      filterName: this.baseColumnConfig?.filter?.variable_value || "",
      namedLoad: null,
      fetchedFilters: null,
      subOpen: false,
      readyToTeleport: false,
    };
  },
  created() {
    this.fetchFilters();
  },
  mounted() {
    this.$nextTick(() => {
      this.readyToTeleport = true;
    });
  },
  methods: {
    selectionChanged(data) {
      this.$emit("selectionChanged", data);
    },
    changeGrouping(group, e) {
      let workingBaseConfig = _.cloneDeep(this.baseColumnConfig);
      workingBaseConfig.groupBy =
        workingBaseConfig.groupBy == group ? null : group;
      store.commit("config/saveTableConfig", {
        config: workingBaseConfig,
        template: this.configString + "_baseconfig",
      });
    },
    namedSave() {
      if (this.filterName != "") {
        store
          .dispatch("config/saveNamedConfig", {
            system_function: "web_column_filters",
            template: this.configString,
            name: this.filterName,
            value: this.workingFilter,
            defaultUser: this.workingFilter.available_for_all,
          })
          .then(() => {
            this.fetchFilters();
          });
      }
    },
    toggleSubOpen() {
      this.subOpen = !this.subOpen;
    },
    loadSaved() {
      this.workingFilter = _.cloneDeep(JSON.parse(this.namedLoad.string_value));
      this.filterName = this.namedLoad.variable_value;
    },
    loadSavedAndApply() {
      this.workingFilter = _.cloneDeep(JSON.parse(this.namedLoad.string_value));
      this.filterName = this.namedLoad.variable_value;
      this.applyFilter();
    },
    fetchFilters() {
      store
        .dispatch("config/loadNamedConfigs", {
          system_function: "web_column_filters",
          template: this.configString,
        })
        .then((data) => {
          this.fetchedFilters = data.map((f) => {
            return { ...f, available_for_all: f.username === "Default" };
          });
        });
    },
    changeSorting(sort, e) {
      let workingBaseConfig = _.cloneDeep(this.baseColumnConfig);
      if (workingBaseConfig.sortKey === undefined) {
        workingBaseConfig.sortKey = [];
      }
      if (workingBaseConfig.sortDir === undefined) {
        workingBaseConfig.sortDir = [];
      }
      if (e.ctrlKey) {
        console.log("ctrl");
        if (workingBaseConfig.sortKey.includes(sort)) {
          let i = workingBaseConfig.sortKey.indexOf(sort);
          // if it's last we swap;
          workingBaseConfig.sortDir.splice(i, 1);
          workingBaseConfig.sortKey.splice(i, 1);
        }
      } else if (e.shiftKey) {
        console.log("shift");
        if (workingBaseConfig.sortKey.includes(sort)) {
          let i = workingBaseConfig.sortKey.indexOf(sort);
          // if it's last we swap;
          workingBaseConfig.sortDir[i] = [
            workingBaseConfig.sortDir[i] == "desc" ? "asc" : "desc",
          ];
        } else {
          workingBaseConfig.sortKey.push(sort);
          workingBaseConfig.sortDir.push("desc");
        }
      } else {
        //replace
        console.log("no shift");
        if (
          workingBaseConfig.sortKey.includes(sort) &&
          workingBaseConfig.sortKey.length == 1
        ) {
          console.log("swapping direction");
          workingBaseConfig.sortDir = [
            workingBaseConfig.sortDir[0] == "desc" ? "asc" : "desc",
          ];
        } else {
          console.log("resetting sort");
          workingBaseConfig.sortKey = [sort];
          workingBaseConfig.sortDir = ["desc"];
        }
      }
      store.commit("config/saveTableConfig", {
        config: workingBaseConfig,
        template: this.configString + "_baseconfig",
      });
      e.preventDefault();
    },
    changeFilter(column, e) {
      console.log(column, e);
      let workingBaseConfig = _.cloneDeep(this.baseColumnConfig);
      if (!workingBaseConfig.filter) {
        workingBaseConfig.filter = {
          type: "and-group",
          filters: [{ type: "in", key: column, values: [] }],
        };
      } else if (column) {
        workingBaseConfig.filter.filters.push({
          type: "in",
          key: column,
          values: [],
        });
      }
      this.workingFilter = _.cloneDeep(workingBaseConfig.filter);
      this.filterPopupOpen = true;
    },
    applyFilter() {
      let workingBaseConfig = _.cloneDeep(this.baseColumnConfig);
      workingBaseConfig.filter = this.workingFilter;
      store.commit("config/saveTableConfig", {
        config: workingBaseConfig,
        template: this.configString + "_baseconfig",
      });

      this.workingFilter = _.cloneDeep(workingBaseConfig.filter);
    },
    assignFilter() {
      this.applyFilter();
      store.dispatch("config/saveConfig", {
        system_function: "webcolumns",
        template: this.configString + "_baseconfig",
      });
    },
    clearFilter() {
      this.removeFilter();
      this.assignFilter();
    },
    removeFilter() {
      let workingBaseConfig = _.cloneDeep(this.baseColumnConfig);
      workingBaseConfig.filter = null;
      store.commit("config/saveTableConfig", {
        config: workingBaseConfig,
        template: this.configString + "_baseconfig",
      });

      this.workingFilter = null;
    },
  },

  computed: {
    ...mapGetters({
      columnConfigByTemplate: "config/columnConfigByTemplate",
      canUser: "permissions/canUser",
    }),
    columnConfig() {
      return this.columnConfigByTemplate(this.configString);
    },
    baseColumnConfig() {
      return this.columnConfigByTemplate(this.configString + "_baseconfig");
    },
    sortKey() {
      if (
        this.baseColumnConfig.sortKey &&
        this.baseColumnConfig.sortKey.length > 0
      ) {
        return this.baseColumnConfig.sortKey;
      }
      if (this.defaultSortKey) {
        return this.defaultSortKey.constructor === Array ? this.defaultSortKey : [this.defaultSortKey];
      }
      if (this.primaryKey) {
        return [this.primaryKey];
      }
      return null;
    },
    sortDir() {
      if (
        this.baseColumnConfig.sortDir &&
        this.baseColumnConfig.sortDir.length > 0
      ) {
        return this.baseColumnConfig.sortDir;
      }
      if (this.defaultSortDir) {
        return this.defaultSortDir.constructor === Array ? this.defaultSortDir : [this.defaultSortDir];
      }
      return ["asc"];
      return null;
    },
    usesSearch() {
      return this.searchable || this.baseColumnConfig?.searchKey?.length > 0;
    },
    searchKey() {
      return this.baseColumnConfig?.searchKey?.length > 0
        ? this.baseColumnConfig?.searchKey
        : this.searchfield;
    },
    tableType() {
      return this.baseColumnConfig.tableType || null;
    },
    groupedBy() {
      return this.baseColumnConfig.groupBy || this.defaultGroupBy;
    },
    activeFilter() {
      return this.baseColumnConfig.filter || null;
    },
    readableFilter() {
      return this.activeFilter
        ? readableFilter(this.activeFilter).slice(1, -1)
        : null;
    },
    filteredData() {
      return this.activeFilter
        ? this.data.filter((obj) => filterFunc(obj, this.activeFilter))
        : this.data;
    },
  },
  components: { FilterComponent },
};
</script>