<template>
  <div
  class="icon-sizing disable-auto-expand"
    :class="{'resizing': resizing == true,
      [iconSizingClass]:true
    }"
  >
    <component :is="$route.meta.layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import store from "@/store";

export default {
  components: {
  },
  computed: {
    hideSideBarOnXl() {
      return store.state.hideSideBarOnXl;
    },
   
    iconSizingClass(){
      return 'icon-sizing-'+store.state.iconSizing;
    }
  },
  data() {
    return {
      resizing: false,
    };
  },
  mounted() {
    let vm = this;
    window.addEventListener("resize", function () {
      vm.resizing = true;
      //console.log("resizing!");
      clearTimeout(window.resizer);
      window.resizer = setTimeout(
        function () {
          vm.resizing = false;
          //console.log("stopped resizing!");
        },
        100
      );
      store.commit("checkMobile");
    });
  },
  watch: {
    $route() {
    },
    
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
