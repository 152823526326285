
import axios from "@/requests/axios";
import { storeKey } from "vuex";
import { getStorage, setStorage, removeStorage, checkStorage } from "../../helpers/storage";
import { execute_json } from "@/helpers/requests";

const state = () => ({
    company_key: getStorage("company_key") || null,
    token:getStorage("token") || null
});

// getters
const getters = {

}

// actions
const actions = {
    login({ commit, dispatch }, {company_id,pincode}) {
        return new Promise((resolve, reject) => {
            axios.post("mobile/status/login",{
                mobilePin:pincode,
                companyId:company_id,
            }).then(({data})=>{
                console.log(data);
                commit("setLogin",{company_id,token:data.token})
                resolve(data);
            }).catch(err=>{
                reject(err);
            })
            
        });
    },
    logout({ commit }) {
        return new Promise((resolve, reject) => {
            commit("clearRemember");
            commit("setLogin", { });
            resolve();
        })
    }
}

// mutations
const mutations = {
    setLogin(state, {  company_id, token}) {
        state.company_id = company_id;
        state.token = token;
        if (company_id !== undefined) {
            setStorage('last_company', company_id, false);
        }
    },
    setRemember(state, { company_key, company_id, company_name, company_email, default_department, usertype, web_only_machine_interface, web_single_machine,dashboard_only }) {
        setStorage('company_key', company_key);
        setStorage('company_id', company_id);
        setStorage('company_name', company_name);
        setStorage('company_email', company_email);
        setStorage('default_department', default_department);
        setStorage('usertype', usertype);
        setStorage('only_machine_interface', web_only_machine_interface);
        setStorage('web_single_machine', web_single_machine);
        setStorage('dashboard_only',dashboard_only);
        setStorage('super_confident',dashboard_only, false);
    },
    clearRemember() {
        removeStorage('company_key');
        removeStorage('company_id');
        removeStorage('company_name');
        removeStorage('company_email');
        removeStorage('default_department');
        removeStorage('usertype');
        removeStorage('only_machine_interface');
        removeStorage('web_single_machine');
        removeStorage('dashboard_only');
    },
    setUsername(state, username) {
        state.username = username;
        setStorage('username', username);
    },
    setLanguage(state, code) {
        state.lang_code = code;
        setStorage("lang_code", code);
    },
    lastPath(state, payload) {
        console.log(payload);
        let path = payload.path;
        let env = payload.env;
        state.path = path;
        setStorage(state.company_id + "_lastpath", path, false);
        setStorage("lastpath", path, false);
        if (env === 'default') {
            console.log("setting default path - "+path);
            state.defaultpath = path;
            setStorage(state.company_id + "_lastdefaultpath", path, false);
            setStorage("lastdefaultpath", path, false);
        } else if (env === 'interface') {
            state.interfacepath = path;
            setStorage(state.company_id + "_lastinterfacepath", path, false);
            setStorage("lastinterfacepath", path, false);
        }
    },
    lastSuperType(state, supertype) {
        state.supertype = supertype;
        setStorage(state.company_id + "_lastsupertype", supertype, false);
        setStorage("lastsupertype", supertype, false);
    },
    lastMachine(state, machine) {
        if (machine !== 'all' && machine !== 'undefined' && machine !== undefined) {
            state.machine = machine;
            setStorage(state.company_id + "_lastmachine", machine, false);
            setStorage("lastmachine", machine, false);
        }
    },
    setSettings(state, data) {
        state.settings = data;
        setStorage(state.username + "_usersettings", data);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}