export const filterFunc = function (obj, filter) {
    //console.log(filter);
    switch (filter.type) {
        case 'and-group':
            return !filter.filters.some(sub => !filterFunc(obj, sub));
        case 'or-group':
            return filter.filters.some(sub => filterFunc(obj, sub));
        case 'in':
            return filter.values.includes(obj[filter.key]);
        case 'not-in':
            return !filter.values.includes(obj[filter.key]);
        case 'equals':
            return filter.value == obj[filter.key];
        case 'not-equals':
            return filter.value != obj[filter.key];
        case 'larger-than':
            return filter.value < obj[filter.key];
        case 'less-than':
            return filter.value > obj[filter.key];
        case 'larger-eq-than':
            return filter.value <= obj[filter.key];
        case 'less-eq-than':
            return filter.value >= obj[filter.key];
        case 'contains':
            return obj[filter.key].includes(filter.value);
        case 'not-contains':
            return !obj[filter.key].includes(filter.value);
        case 'starts-with':
            return obj[filter.key].startsWith(filter.value);
        case 'ends-with':
            return obj[filter.key].endsWith(filter.value);
        case 'larger-than-numerical':
            return +filter.value < +obj[filter.key];
        case 'less-than-numerical':
            return +filter.value > +obj[filter.key];
        case 'larger-eq-than-numerical':
            return +filter.value <= +obj[filter.key];
        case 'less-eq-than-numerical':
            return +filter.value >= +obj[filter.key];
        default:
            return true;
    }
}

export const readableFilter = function (filter) {
    switch (filter.type) {
        case 'and-group':
            return "( " + filter.filters.map(f => readableFilter(f)).join(" AND ") + " )";
        case 'or-group':
            return "( " + filter.filters.map(f => readableFilter(f)).join(" OR ") + " )";
        case 'in':
            return filter.key + " IN [" + filter.values.join(", ") + "]";
        case 'not-in':
            return filter.key + " NOT IN [" + filter.values.join(", ") + "]";
        case 'equals':
            return filter.key + " = " + filter.value;
        case 'larger-than':
            return filter.key + " > '" + filter.value + "'";
        case 'larger-than-numerical':
            return filter.key + " > " + filter.value;
        case 'less-than':
            return filter.key + " < '" + filter.value + "'";
        case 'less-than-numerical':
            return filter.key + " < " + filter.value;
        case 'contains':
            return filter.key + " includes '" + filter.value + "'";
        case 'not-contains':
            return filter.key + " doesn't include '" + filter.value + "'";
        case 'starts-with':
            return filter.key + " starts with '" + filter.value + "'";
        case 'ends-with':
            return filter.key + " ends with '" + filter.value + "'";
        case 'larger-eq-than':
            return filter.key + " >= '" + filter.value + "'";
        case 'larger-eq-than-numerical':
            return filter.key + " >= " + filter.value;
        case 'less-eq-than':
            return filter.key + " <= '" + filter.value + "'";
        case 'less-eq-than-numerical':
            return filter.key + " <= " + filter.value;
        case 'not-equals':
            return filter.key + " <> " + filter.value;
    }
}
/*
{
          type: "and-group",
          filters: [
            { type: "in", key: "number", values: [1,2,3,4,5] },
            {
              type: "and-group",
              filters: [
                {
                  type: "or-group",
                  filters: [
                    {
                      type: "equals",
                      key: "status",
                      value: "R",
                    },
                    {
                      type: "equals",
                      key: "status",
                      value: "S",
                    },
                  ],
                },
                {
                  type: "equals",
                  key: "status",
                  value: "R",
                },
              ],
            },
          ],
        };
        */