<template>
  <div class="hover-wrap">
    <div
      :class="t"
      class="indicator t-small"
      @mouseenter="startHover"
      @mouseleave="stopHover"
    >
      {{ t_used }}
    </div>
  
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: ["data"],
  data() {
    return {
      hoverDelay: null,
      hovered: false,
    };
  },
  methods: {
    startHover() {
      console.log("mouse-in");
      this.hoverDelay = setTimeout(
        (() => {
          this.hovered = true;
        }).bind(this),
        500
      );
    },
    stopHover() {
      console.log("mouse-out");
      clearTimeout(this.hoverDelay);
    },
  },
  computed: {
    ...mapState({
    }),
    t_used() {
      switch (this.period) {
        case "shift":
          return this.data.t_percent_shift;
          break;
        case "24h":
          return this.data.t_percent_24;
          break;
        case "today":
          return this.data.t_percent_today;
          break;
        case "calendar":
          return this.data.t_percent_calendar;
          break;
      }
    },
    t() {
      if (+this.t_used >= +this.data.bar_green_limit) {
        return "c-green";
      } else if (+this.t_used >= +this.data.bar_yellow_limit) {
        return "c-yellow";
      } else return "c-red";
    },
  },
  components: {
  },
};
</script>