<template>
    <div>
        <h1>{{data.object_description}}</h1> 
        <p>{{data.line_no !== undefined && data.line_no != "-"  ? data.line_no+" - " : ""}}{{data.machine_id}}</p>
    </div>
</template>
<script>
export default {
    props:[
        'data'
    ]
}
</script>