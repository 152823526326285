import store from "@/store";
import _ from 'lodash';

/*

 store.dispatch("updates/addUpdate", {
      action: "machines/loadMachines",
      parameters: {},
      primaryKey: null,
      interval: 3,
      source: "production-status-" + this.cid,
    });

*/
export class Pulse {
    constructor(updates, options) {
        this.updates = Array.isArray(updates) ? updates : [updates];
        this.options = options;
        this.dispatch = store.dispatch;
        this.commit = store.commit;
        this.internalId = _.uniqueId('pulse-');
        this.queueAll();
        this.stop = this.stop.bind(this);
        this.force = this.force.bind(this);
    }

    queueAll() {
        let internalId = this.internalId;
        let dispatch = this.dispatch;
        _.each(this.updates, function (update) {
            dispatch("updates/addUpdate", {
                action: update.action,
                parameters: update.parameters || {},
                primaryKey: update.primaryKey || null,
                interval: update.interval,
                source: internalId
            })
        });
    }

    queueOne(update) {
        let internalId = this.internalId;
        let dispatch = this.dispatch;
        dispatch("updates/addUpdate", {
            action: update.action,
            parameters: update.parameters || {},
            primaryKey: update.primaryKey || null,
            interval: update.interval,
            source: internalId
        });
    }

    add(update) {
        this.updates.push(update);
        this.queueOne(update);
    }

    stop() {
        let internalId = this.internalId;
        let dispatch = this.dispatch;
        _.each(this.updates, function (update) {
            dispatch("updates/removeUpdate", {
                action: update.action,
                parameters: update.parameters || {},
                primaryKey: update.primaryKey || null,
                interval: update.interval,
                source: internalId
            })
        });
    }

    force() {
        //console.log("forcing reupdate");
        let dispatch = this.dispatch;
        _.each(this.updates, function (update) {
            dispatch(update.action, update.parameters);
        });
    }

    shock(name) {
        let commit = this.commit;
        _.each(this.updates.filter(u => name === undefined || u.name === name), function (update) {
            if (update.primaryKey) {
                let primaryPar = update.parameters?.[update.primaryKey];
                commit("updates/setLastRunKeyedZero", { action: update.action, primaryPar });
                commit("updates/setKeyedFlag", { action: update.action, primaryPar, flag: false });
            }else{
                commit('updates/setLastRunPlannedZero',update.action);
                commit('updates/setUpdateFlag',{action:update.action,flag:false});
            }
        })
    }

    stopAndClean() {
        this.stop();
        this.updates = [];
    }

    setNew(updates) {
        console.log("beginning again!");
        this.stopAndClean();
        this.updates = Array.isArray(updates) ? updates : [updates];
        console.log(this.updates);
        this.queueAll();
    }
}
