<template>
  <div class="indicator status-small" :class="status" v-if="status">
      <i class="fas fa-toolbox centered" ></i>
  </div>
</template>
<script>
export default {
  props: ["data"],
  computed: {
    status() {
      switch (this.data.sf373_status) {
        case 1:
          return "c-green";
        case 2:
          return "c-yellow";
        case 3:
          return "c-red";
        case 4:
          return "c-gray";
        default:
          return null;
      }
    },
  },
};
</script>