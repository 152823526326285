<template>
    <main class="form-signin text-center">
        <img class="" src="@/assets/Balthzar.png" alt="" style="max-height:60px;"/>
        <div class="card">
            <div class="card-header">
                <div class="title">{{title}}</div>
                <div class="description">{{description}}</div>
            </div>
            <div class="card-body">
                <slot></slot>
            </div>
        </div>
    </main>
</template>
<script>
import { useRoute } from 'vue-router';
import { watch, ref } from 'vue';
export default {
    setup() {
    const route = useRoute();

    const title = ref(route.meta.title);
    const description = ref("");

    watch(() => route.path,
     () => {
         console.log("boo");
        title.value = route.meta.title;
        description.value = route.meta.description;
     }
    )
    return {
      title,
      description
    }
  }
}
</script>