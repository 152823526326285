<template>
  <div class="table-responsive">
    <div class="search-field" v-if="searchable">
      <input
        type="text"
        class="form-control"
        placeholder="search"
        v-model="searchText"
        @focus="enableKeyboard"
      />
    </div>
    <table
      class="table table-sm caption-top table-borderless sticky sticky-headers"
      :class="{
        minimal: tableType == 'minimal',
        'table-striped': stripedTables,
      }"
    >
      <caption v-if="caption" class="caption-top">
        {{
          $t(caption)
        }}
      </caption>
      <thead>
        <tr v-if="showHeaders" :class="{ 'inverted-headers': invertedHeaders }">
          <th v-for="(v, k) in columnConfig" :key="k" :class="v.class">
            {{ v.tlabel ? $t(v.tlabel) : v.label || k }}
          </th>
        </tr>
        <tr v-if="advanced && subOpen">
          <th v-for="(v, k) in columnConfig" :key="k" :class="v.class">
            <div
              class="advanced-actions"
              v-if="data[0]?.[k?.replace('custom_', '')] !== undefined"
            >
              <i
                class="fas fa-filter"
                @click="$emit('filter', k?.replace('custom_', ''), $event)"
              ></i>
              <i
                class="fas fa-divide"
                @click="$emit('group', k?.replace('custom_', ''))"
              ></i>
              <i
                class="fas"
                @click="$emit('sort', k?.replace('custom_', ''), $event)"
                :class="{
                  'fa-arrow-up':
                    sortKey.includes(k?.replace('custom_', '')) &&
                    sort_dir[sortKey.indexOf(k?.replace('custom_', ''))] ==
                      'desc',
                  'fa-arrow-down':
                    sortKey.includes(k?.replace('custom_', '')) &&
                    sort_dir[sortKey.indexOf(k?.replace('custom_', ''))] ==
                      'asc',
                  'faded fa-arrow-up': !sortKey.includes(
                    k?.replace('custom_', '')
                  ),
                }"
              ></i>
            </div>
          </th>
        </tr>
      </thead>
      <tbody v-if="groupedBy === null">
        <tr
          v-for="(row, si) in searchedData"
          v-bind:key="row[primaryKey] + '-' + si"
          :class="{
            highlight: highlights.includes(row[primaryKey]),
            disabled: useIntersect && !row.intersect,
            ...colorize(row[primaryKey]),
          }"
          @click="toggleRow(row[primaryKey], row)"
        >
          <td
            v-for="(v, k) in columnConfig"
            v-bind:key="row[primaryKey] + '-' + k"
            :class="v.class"
          >
            <component
              v-if="v.component"
              :is="v.component"
              :data="row"
              :value="row[v.key || k]"
              :config="v.config"
            ></component>
            <span v-else>
              {{ row[v.key || k] }}
            </span>
          </td>
        </tr>
      </tbody>
      <tbody v-for="(group, groupKey) in groupedData" :key="groupKey">
        <tr class="group-header">
          <th v-for="(v, k) in columnConfig" :key="k" :class="v.class">
            <span v-if="k?.replace('custom_', '') === groupedBy"
              >{{ v.tlabel ? $t(v.tlabel) : v.label || k }}:
              {{ group[0]?.[k?.replace("custom_", "")] }}</span
            >
          </th>
        </tr>
        <tr
          v-for="(row, si) in group"
          v-bind:key="row[primaryKey] + '-' + si"
          :class="{
            highlight: highlights.includes(row[primaryKey]),
            disabled: useIntersect && !row.intersect,
            ...colorize(row[primaryKey]),
          }"
          @click="toggleRow(row[primaryKey], row)"
        >
          <td
            v-for="(v, k) in columnConfig"
            v-bind:key="row[primaryKey] + '-' + k"
            :class="v.class"
          >
            <component
              v-if="v.component"
              :is="v.component"
              :data="row"
              :value="row[v.key || k]"
              :config="v.config"
            ></component>
            <span v-else>
              {{ row[v.key || k] }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <span v-if="searchable && sortedData.length === 0">
      No search results
    </span>
  </div>
</template>
<script>
import colorBox from "./items/customizable/colorBox";
import * as ItemComponents from "./items";
import _ from "lodash";
import { mapState } from "vuex";
import store from "@/store";

export default {
  props: {
    columnConfig: Object,
    data: Array,
    primaryKey: String,
    sortKey: {},
    singleSelect: {
      Boolean,
      default: false,
    },
    showHeaders: {
      Boolean,
      default: true,
    },
    caption: {
      String,
    },
    useIntersect: {
      Boolean,
      default: false,
    },
    deselect: {
      Boolean,
      default: false,
    },
    sort_dir: {
      default: ["desc"],
    },
    /*
    sort_type:{
      default:['alfa']
    },
    */
    searchable: {
      Boolean,
      default: false,
    },
    tableType: {
      default: null,
    },
    initialHigh: {
      default: [],
    },
    searchfield: {
      default: "*",
    },
    colors: {
      default: null,
    },
    selectable: {
      default: true,
    },
    initialSearch: {
      default: "",
    },
    advanced: {
      default: false,
    },
    groupedBy: {
      default: null,
    },
    subOpen: {
      default: true,
    },
    /*
    presort_by_key:{
      default:null
    },
    presort_type:{
      default:"alfa"
    },
    presort_dir:{
      default:"desc"
    }
    */
  },
  emits: ["selectionChanged", "group", "sort", "filter"],
  data() {
    return {
      selectedRows: [],
      highlights: [...this.initialHigh],
      searchText: this.initialSearch,
      keyboardEnabled: false,
      keyboardTarget: null,
      cid: _.uniqueId("btable-"),
      numericalColumns: ["record_no", "_sort"],
    };
  },
  created() {},
  methods: {
    toggleRow(id, row) {
      if (!this.selectable) {
        return null;
      }
      store.commit("config/activateBTable", this.cid);
      if (this.useIntersect && !row.intersect) {
        return null;
      }
      if (this.singleSelect) {
        if (!this.highlights.includes(id)) {
          this.highlights = [id];
        } else if (this.deselect) {
          this.highlights = [];
        }
      } else {
        if (!this.highlights.includes(id)) {
          this.highlights.push(id);
        } else {
          this.highlights = this.highlights.filter((i) => i !== id);
        }
      }
      this.selectedRows = _.filter(this.data, (row) =>
        this.highlights.includes(row[this.primaryKey])
      );
      this.$emit("selectionChanged", { selectedRowsData: this.selectedRows });
    },
    colorize(pk) {
      if (this.colors === null) {
        return {};
      } else {
        let colors = {};
        colors[this.colors[pk]] = true;
        return colors;
      }
    },
    enableKeyboard(e) {
      this.keyboardEnabled = true;
      this.keyboardTarget = e.target;
      document.addEventListener("click", this.disableKeyboard);
    },
    disableKeyboard(e) {
      if (
        e.path.findIndex((p) => {
          return p?.classList?.contains("keyboard-wrap");
        }) > -1 ||
        e.target == this.keyboardTarget
      ) {
        //ignore event
      } else {
        this.keyboardEnabled = false;
        console.log(e);
        document.removeEventListener("click", this.disableKeyboard);
      }
    },
  },
  watch: {
    activeBTable(id) {
      if (id !== this.cid) {
        this.highlights = [];
        //this.$emit('selectionChanged',{selectedRowsData:this.selectedRows});
      }
    },
  },
  computed: {
    ...mapState({
      activeBTable: (state) => state.config.activeBTable,
      stripedTables: (state) => state.stripedTables,
      invertedHeaders: (state) => state.invertedHeaders,
    }),
    sort_by_key() {
      return this.sortKey || this.primaryKey;
    },
    searchedData() {
      if (this.searchable && this.searchText != "") {
        if (typeof this.searchfield == "string") {
          return this.sortedData.filter((i) => {
            let searchTerm = "" + i[this.searchfield] || "";
            return searchTerm
              .toLowerCase()
              .includes(this.searchText.toLowerCase());
          });
        } else {
          let searchArr = this.searchfield;
          let st = this.searchText.toLowerCase();
          return this.sortedData.filter((i) =>
            searchArr.some((sf) => ("" + i[sf])?.toLowerCase().includes(st))
          );
        }
      }
      return this.sortedData;
    },
    groupedData() {
      if (this.groupedBy === null) {
        return [];
      } else {
        return _.groupBy(this.searchedData, this.groupedBy);
      }
    },
    sortArray() {
      if (!this.sortKey) {
        return [];
      }
      if (typeof this.sort_by_key == "string") {
        return [this.sort_by_key];
      } else if (this.sort_by_key instanceof Array) {
        return this.sort_by_key;
      }
      return [];
    },
    dirArray() {
      if (typeof this.sort_dir == "string") {
        return [this.sort_dir];
      } else if (this.sort_dir instanceof Array) {
        return this.sort_dir;
      }
      return [];
    },
    typedSortArray() {
      let arr = [];
      for (let i = 0; i < this.sortArray.length; i++) {
        if (this.numericalColumns.includes(this.sortArray[i])) {
          arr.push((row) => {
            return +row[this.sortArray[i]];
          });
        } else {
          arr.push(this.sortArray[i]);
        }
      }
      return arr;
    },
    sortedData() {
      return _.orderBy(this.data, this.typedSortArray, this.dirArray);
    },
  },
  components: {
    ...ItemComponents,
    colorBox,
  },
};
</script>
<style scoped lang="scss">
tr.highlight {
  background: black;
  color: white;
}
table.table-striped {
  tr.highlight {
    background: black;
    color: white;
  }
}
tr.disabled {
  color: lightgrey;
  cursor: not-allowed;
}
th,
td {
  text-align: left;
  &.minimal {
    width: 44px;
  }
}
tr {
  cursor: pointer;
}
.group-header {
  background: #eeeeee;
  th {
    color: #444444;
  }
  th.grouped-by {
    color: black;
  }
}
.faded {
  opacity: 0.5;
}
</style>
