<template>
    <div>
        <span class="nosplit">{{d}}</span>
    </div>
</template>
<script>
import {secondsToHhMm} from "@/helpers/dates";

export default {
    props:[
        'data',
        'value',
        'config'
    ],
    computed:{
        d(){
           return isNaN(this.value) ? "NaN" : secondsToHhMm(this.value);
        }
    }
}
</script>