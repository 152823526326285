<template>
  <div v-if="locationType !== null">
    <span>{{ $t(translations[locationType]) }}:{{
        data[locationType + "_id"]
      }}</span
    >
  </div>
</template>

<script>
export default {
  props: ["data", "value", "config"],
  data() {
    return {
      options: ["machine", "department", "zone", "category"],
      translations: {
        machine: "1",
        department: "2",
        zone: "331",
        category: "332",
      },
    };
  },
  computed: {
    locationType() {
      let option = null;
      this.options.forEach((o) => {
        if (this.data[o + "_id"] !== null) {
          option = o;
          return option;
        }
      });
      return option;
    },
  },
};
</script>

<style>
</style>