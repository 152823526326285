<template>
  <div class="filterstep align-left" :class="{ isroot }">
    <div v-if="filter.type.indexOf('-group') > -1" class="filter-children">
      <!-- these are groups -->
      <select v-model="filter.type">
        <option value="and-group">AND</option>
        <option value="or-group">OR</option>
      </select>
      <button class="btn" @click="addConditionToGroup">
        <i class="fas fa-plus"></i>
      </button>
      <button class="btn" @click="addGroupToGroup">
        <i class="fas fa-folder-plus"></i>
      </button>
      <button class="btn" @click="removeGroup" v-if="!isroot">
        <i class="fas fa-times"></i>
      </button>
      <filter-component
        v-for="(subfilter, index) in filter.filters"
        :key="index"
        :data="data"
        :filter="subfilter"
        :isroot="false"
        @delete="deleteAtIndex(index)"
      ></filter-component>
    </div>
    <div v-else class="filterpart">
      <button class="btn pull-right" @click="removeGroup" v-if="!isroot">
        <i class="fas fa-times"></i>
      </button>
      [<select
        class="textlike blue"
        v-model="filter.key"
        :style="{ width: keySize }"
        ref="keysizer"
        @change="resizeKey"
      >
        <option :value="k" v-for="k in Object.keys(data[0]).sort()" :key="'keyselect-' + k">
          {{ k }}
        </option></select
      >]
      <select
        v-model="filter.type"
        :style="{ width: typeSize }"
        @change="resizeOperator"
        ref="operator"
        class="textlike green"
      >
        <option value="equals">=</option>
        <option value="not-equals">!=</option>
        <option value="in">IN</option>
        <option value="not-in">NOT IN</option>
        <option value="starts-with">starts with</option>
        <option value="ends-with">ends with</option>
        <optgroup label="string comparison">
          <option value="larger-than">></option>
          <option value="larger-eq-than">>=</option>
          <option value="less-than">&lt;</option>
          <option value="less-eq-than">&lt;=</option>
          <option value="contains">contains</option>
          <option value="not-contains">not contains</option>
        </optgroup>
        <optgroup label="numerical comparison">
          <option value="larger-than-numerical">></option>
          <option value="larger-eq-than-numerical">>=</option>
          <option value="less-than-numerical">&lt;</option>
          <option value="less-eq-than-numerical">&lt;=</option>
        </optgroup>
      </select>
      <input
        type="text "
        class="textlike"
        placeholder="< input value >"
        v-model="filter.value"
        v-if="!['in', 'not-in'].includes(filter.type)"
      />
      <select
        type="text"
        v-model="filter.values"
        v-if="['in', 'not-in'].includes(filter.type)"
        multiple
        class="form-control"
      >
        <option
          :value="v"
          v-for="v in possibleValues"
          :key="'optionselect-' + v"
        >
          {{ v }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "filter-component",
  emits: ["delete", "deletegroup", "add", "addgroup"],
  props: ["filter", "data", "isroot"],
  data() {
    return {
      keySize: "auto",
      typeSize: "auto",
    };
  },
  computed: {
    possibleValues() {
      if (["in", "not-in"].includes(this.filter.type)) {
        return [...new Set(this.data.map((item) => item[this.filter.key]))];
      }
      return [];
    },
    type() {
      return this.filter.type;
    },
  },
  mounted() {
    //this.resizeKey();
    if (this.$refs.keysizer) {
      console.log("changing..");
      var event = new Event("change");

      // Dispatch it.
      this.$refs.keysizer.dispatchEvent(event);
    }
    if (this.$refs.operator) {
      console.log("changing..");
      var event = new Event("change");

      // Dispatch it.
      this.$refs.operator.dispatchEvent(event);
    }
  },
  methods: {
    addConditionToGroup() {
      this.filter.filters.push({
        type: "equals",
        key: "",
        value: "",
      });
    },
    addGroupToGroup() {
      this.filter.filters.push({
        type: "and-group",
        filters: [],
      });
    },
    removeGroup() {
      this.$emit("delete");
    },
    removeCondition() {
      this.$emit("delete");
    },
    deleteAtIndex(index) {
      this.filter.filters.splice(index, 1);
    },
    selectSize(text, event) {
      let tempSelect = document.createElement("select"),
        tempOption = document.createElement("option");

      tempOption.textContent = text;
      tempSelect.classList.add("textlike");
      tempSelect.style.cssText += `
      visibility: hidden;
      position: fixed;
      `;
      tempSelect.appendChild(tempOption);
      event ? event.target.after(tempSelect) : document.body.after(tempSelect);

      let w = tempSelect.getBoundingClientRect().width * (event ? 1 : 1.1);

      tempSelect.remove();
      return w;
    },
    resizeKey(event) {
      console.log(event);
      let text = event
        ? event.target.options[event.target.selectedIndex]?.text
        : this.filter.key || this.filter.key;
      let w = this.selectSize(text, event);

      const tempSelectWidth = Math.max(w, 50);
      this.keySize = `${tempSelectWidth}px`;
    },

    resizeOperator(event) {
      console.log(event);
      let text = event
        ? event.target.options[event.target.selectedIndex]?.text
        : "NOT IN" || "NOT IN";
      let w = this.selectSize(text, event);

      const tempSelectWidth = Math.max(w, 20);
      this.typeSize = `${tempSelectWidth}px`;
    },
  },
  watch: {
    type() {
      if (["in", "not-in"].includes(this.filter.type) && !this.filter.values) {
        this.filter.values = [];
      } else if (!this.filter.value) {
        this.filter.value = "";
      }
    },
  },
};
</script>

<style lang="scss">
</style>