<template>
  <div>
    <span class="nosplit">{{ d }}</span>
  </div>
</template>
<script>
import { shortDateTime } from "@/helpers/dates";

export default {
  props: ["data", "value", "config"],
  computed: {
    d() {
      if (this.config && this.config.hourOnly) {
        return this.value.slice(11, 13);
      }
      if (this.config && this.config.dayOnly) {
        return this.value.slice(0, 10);
      }
      let d_formatted = new Date(this.value).toBalthzarTime();
      if (d_formatted === "Invalid Date") {
        return this.value;
      } else {
        if (this.config && this.config.short) {
          return shortDateTime(this.value);
        }
        return d_formatted;
      }
    },
  },
};
</script>