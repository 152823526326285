<template>
    <div>
        {{$t(""+value)}}
    </div>
</template>
<script>
export default {
    props:[
        'value'
    ],
    computed:{
    }
}
</script>