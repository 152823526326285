import store from "@/store";
import _ from 'lodash';

export class Notification {
    constructor(settings) {
        this.settings = settings;
        this.storeCommit = store.commit;
        this.storeDispatch = store.dispatch;
        this.internalId = _.uniqueId('unote-');
        this.commit();
    }

    static request(settings) {
        return new Notification({
            type: "request",
            state: "pending",
            selfClosing: false,
            playSound: false,
            ...settings
        });
    }

    static message(settings) {
        return new Notification({
            type: "message",
            state: "done",
            selfClosing: true,
            playSound: false,
            ...settings
        });
    }

    static failed(message, settings = {}) {
        return new Notification({
            type: "request",
            state: "fail",
            selfClosing: true,
            playSound: true,
            message,
            ...settings
        })
    }

    commit() {
        this.storeDispatch("notifications/addNotification", { ...this.settings, id: this.internalId });
    }

    update(data) {
        this.storeDispatch("notifications/updateNotification", { id: this.internalId, data });
        console.log(data);
    }

    linkEvent(event) {
        this.storeDispatch("notifications/updateNotification", { id: this.internalId, data: { event } });
        return this;
    }

    ok(data, timeout) {
        let vm = this;
        setTimeout(function () {
            vm.update({
                state: "success",
                selfClosing: true,
                ...data
            })
        }, timeout || 300);
    }

    abort(data) {
        this.update({
            state: "aborted",
            selfClosing: true,
            message: "aborted",
            ...data
        })
    }

    fail(data) {
        this.update({
            state: "fail",
            selfClosing: true,
            playSound: true,
            ...data
        })
    }

    close(){
        this.update({
            state: "fail",
            selfClosing:true,
            playSound:false
        })
    }

}
